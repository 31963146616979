import React, { useContext } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { rgba } from "polished";
import { Section, Box, Title, Span } from "../../components/Core";

import GlobalContext from "../../context/GlobalContext";
import Himage from '../../assets/image/jpeg/dermabg.jpg';
import { device } from "../../utils";
import imgVideo from "../../assets/image/jpeg/video-dermamina.jpg";



const ImgContainer = styled.div`
  margin-top: 50px;
  @media ${device.lg} {
    margin-top: 0px;
    position: absolute;
    top: 25%;
   width:45%;
    right: 0;
    transform: translate(55%, -50%);
  }
  @media ${device.xl} {
    transform: translate(40%, -50%);
  }
  @media (min-width: 1400px) {
    transform: translate(15%, -50%);
  }
`;


const Newbut = styled.div`
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-top: auto;
margin-bottom: 4em;
padding-top: 1em;
padding-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
opacity: 0.4;
font-family: azo-sans-web, sans-serif;
color: #fff;
letter-spacing: 2px;
text-decoration: none;
text-transform: uppercase;
max-width: 100%;
    display: inline-block;
`;




const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 2em;
background-color: hsla(0, 0%, 100%, 0.8);

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: hsla(0, 0%, 100%, 0.8);
font-size: 1em;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 16px;
}

`;






const Imagi = styled.div`

position: absolute;
left: 0%;
top: 0%;
right: 0%;
bottom: 0%;
width: 100%;
height: 100%;
background: black;
background-size: cover;
background-position: center center;
background-position: 0px 0px;

opacity:1;


`;



const SecondText = styled.h3`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 3.2em;

      margin-bottom: 0.4em;
 
      color: #fff;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 26px;
          text-align:center;
      }

`;


const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;


width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 12px;
     text-align:center;
     width: 100%;
 }

`;








  


const Secti = styled.section`


position: relative;

background-color: #000;


@media screen and (max-width: 767px)
{

 }

`;




const Sect = styled.section`

  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;

`;

const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 15px;
  padding-left: 0;

  @media ${device.sm} {
    padding-top: 35px;
    display: flex;
    flex-wrap: wrap;
  }
  @media ${device.lg} {
    justify-content: space-between;
  }

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #19191b;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 9px;
      margin-right: 13px;
    }
  }
`;


const IconButton = styled(Box)`
  cursor: pointer;
  font-size: 22px;
  background-color: ${({ theme }) => theme.colors.light};
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: ${({ theme }) => theme.colors.secondary};
  width: 64px;
  height: 64px;
  @media ${device.sm} {
    width: 100px;
    height: 100px;
  }
  @media ${device.md} {
    width: 124px;
    height: 124px;
  }
`;

const VideoCTA = () => {
  const gContext = useContext(GlobalContext);

  const openVideoModal = (e) => {
    e.preventDefault();
    gContext.toggleVideoModal();
  };

  return (
    <>
      {/* <!-- VideoCTA Area --> */}

      <Secti>
    
 
  
   <Section

     className="position-relative"
     py={["50px", null, "130px", null, "230px"]}
   >
     <Container>
       <Row className="align-items-center">
     
         <Col lg="6" className="order-lg-1 mt-5 mt-lg-0">
           <div
             data-aos="fade-up"
             data-aos-duration="750"
             data-aos-once="true"
           >
             <div>
         <Iwrap>

         
         <Itext as="h2">Are Non Surgical Nose Jobs Safe?</Itext>
         </Iwrap>
               <SecondText>
               Watch a video of our lead cosmetics expert Mina in action 
               </SecondText>

          <Thirdtext>Non Surgical Nose Jobs London are a very safe and reliable alternative to traditional rhinoplasty. This is due to it being a non-invasive treatment, which means less pain, less aftercare and a shorter recovery time.</Thirdtext>
              
             </div>
           </div>
         
         </Col>

         <Col lg="6" className=" position-static order-lg-2">
           <ImgContainer className=" pl-lg-5"   className="shadow"
               data-aos="fade-up"
               data-aos-duration="750"
               data-aos-once="true"
              >
      
      <Box  data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="100">
        <img src={imgVideo} alt="ARE NON SURGICAL NOSE JOBS SAFE?" className=""  />
        <IconButton onClick={openVideoModal}>
          <i className="icon icon-triangle-right-17-2"></i>
        </IconButton>
      </Box>
          
           </ImgContainer>
         </Col>
       </Row>
  
     </Container>
    
   </Section>
   </Secti>
   
      
        

    </>
  );
};

export default VideoCTA;
