import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";

import iconLayout from "../../assets/image/svg/time.svg";
import iconLayers from "../../assets/image/svg/cost.svg";
import iconResponsive from "../../assets/image/svg/recovery.svg";
import results from "../../assets/image/svg/results.svg";
import pain from "../../assets/image/svg/pain.svg";
import last from "../../assets/image/svg/last.svg";


const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;
  color: white;
  margin-bottom: 10px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;


const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 4rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;

  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }

  &.main {
    background: #00afc9;
    color: #efe8de;
    margin: 0 auto;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }

  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }

      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`

const SectionStyled = styled(Section)`
  padding-top: 47px;
  padding-bottom: 23px;
  background: rgba(0,24,47,.7);
text-align:center;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
  @media ${device.md} {
    padding-top: 87px;
    padding-bottom: 63px;
  }
`;

const FeatureCard = ({ iconImage, title, children, ...rest }) => (
  <Box {...rest}>
    <Box mb={[2, 2, 2, 2]} pb={1}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title color="white" variant="card" fontSize="16px" mb={1}>
        {title}
      </Title>
      <Text color="white" fontSize="14px" variant="small">{children}</Text>
    </Box>
  </Box>
);

const RecoveryNon = () => (
  <>
    {/* <!-- Feature section --> */}
    <SectionStyled className="pb-md-5 mb pt-md-5">
      <Container className="pb-md-5 mb pt-md-5">
      <Row className="mb-5">
                  <Col>
                  <HeroT color="dark">NON SURGICAL RHINOPLASTY <Span color="secondary">SUMMARY</Span>{" "}</HeroT>
                  </Col>
                </Row>
        <Row className="justify-content-center">
       
          <Col lg="2" xs="4" className="mb-3 mb-lg-4 "
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-once="false"
            data-aos-delay="100" >
            <FeatureCard title="Treatment Time" iconImage={iconLayout}>
              10 minutes
            </FeatureCard>
          </Col>
          
          <Col lg="2" xs="4" className="mb-3 mb-lg-4"   data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="false"
              data-aos-delay="200">
            <FeatureCard title="Instant Results" iconImage={results}>
           Instant

            </FeatureCard>
          </Col>
          
          <Col lg="2" xs="4" className="mb-3 mb-lg-4"   data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="false"
              data-aos-delay="300">
            <FeatureCard title="Recovery" iconImage={iconResponsive}>
            None

            </FeatureCard>
          </Col>
          <Col lg="2" xs="4" className="mb-3 mb-lg-4" data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="false"
              data-aos-delay="400">
            <FeatureCard title="Pain" iconImage={pain}>
            None / Mild

            </FeatureCard>
          </Col>
          <Col lg="2" xs="4" className="mb-3 mb-lg-4" data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="false"
              data-aos-delay="500">
            <FeatureCard title="Cost" iconImage={iconLayers}>
              £450
            </FeatureCard>
          </Col>
          <Col lg="2" xs="4" className="mb-3 mb-lg-4" data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="false"
              data-aos-delay="600">
            <FeatureCard title="Results Last" iconImage={last}>
            12 – 18 Months

            </FeatureCard>
          </Col>
       
        </Row>

        
   
      
      </Container>
    </SectionStyled>
  </>
);

export default RecoveryNon;
