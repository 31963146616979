import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";

import { Title, Section, Box, Text, Span } from "../../components/Core";
import { breakpoints } from "../../utils";
import trust from "../../assets/image/png/trust.svg"
import google from "../../assets/image/png/google-reviews.svg"
import { device } from "../../utils";
import imgC1 from "../../assets/image/png/test1.png";
import imgC2 from "../../assets/image/png/test2.png";
import imgC3 from "../../assets/image/png/test3.png";
import imgC4 from "../../assets/image/png/test4.png";
import imgC5 from "../../assets/image/png/test5.png";
import imgC6 from "../../assets/image/png/test6.png";
import imgC7 from "../../assets/image/png/test7.png";
import imgC8 from "../../assets/image/png/test8.png";


const SectionStyled = styled(Section)`
  background-color: #f1f1f1;
  border-bottom: 1px solid #ededf4;
  padding-top:5px;
  padding-bottom: 50px;

  @media ${device.md} {
    padding-top:5px;
  
  }
`;


const SliderStyled = styled(Slider)`
  .slick-dots {
    position: relative;
   
    li {
      font-size: 0;
      width: 17px;
      height: 8px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.colors.shadow};
      margin-left: 5px;
      margin-right: 5px;
      transition: 0.5s;
      &.slick-active {
        width: 45px;
        height: 8px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.colors.secondary};
      }
      button {
        width: 100%;
        height: 100%;
        &:before {
          content: none;
        }
      }
    }
  }
`;

const ContentCard = ({
  className,
  image,
  name,
  company,
  children,
  ...rest
}) => (
  <Box
    bg="#FFD9CF"
    border="1px solid"
    borderColor="border"
    p="30px"
    mx="10px"
    borderRadius={10}
    className={`${className}`}
    {...rest}
    css={`
      &:focus {
        outline: none;
      }
    `}
  >
    <Text color="dark" mb={2}>
      {children}
    </Text>
    <Box className={``}>
   
      <div className="flex-grow-1">
        <Title variant="card">
          {name}
        </Title>
        <Text fontSize={2}>{company}</Text>
      </div>
    </Box>
    <Box className="" mr={0}>
        <img src={image} alt="" className="img-fluid" width="80%" />
      </Box>
  </Box>
);

const RhinoRev = () => {
  const slickSettings = {
    dots: false,
    infinite: true,
    autoplay:true,
    cssEase: "linear",
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
   
    responsive: [
      {
        breakpoint: breakpoints.md,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
   <Section bg="#fff" className="pb-md-5 mb pt-md-5"  py={4}>
        <Container fluid className="pb-md-3  pt-md-3"
        
        >
         
          <Row className="justify-content-center mt-0">
            <Col lg="11"
          
            >
              <SliderStyled {...slickSettings}>

              <a href="https://uk.trustpilot.com/review/www.dermamina.com" target="_blank">   <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                    name="Kloe"
                    image={trust}
                  >
              I never thought it was possible to have a better looking nose, you did an amazing job....
                  </ContentCard>
                </Box> </a>
                
                <a href="https://uk.trustpilot.com/review/www.dermamina.com" target="_blank">   <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                    name="Miss Robinson"
                    image={trust}
                  >
              I highly recommend dermamina especially if it's your first non surgical procedure...
                  </ContentCard>
                </Box> </a>

                <a href="https://uk.trustpilot.com/review/www.dermamina.com" target="_blank">    <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                    name="Simona"
                    image={trust}
                  >
             The consultation with Mina has been the most refreshing and personal...
                  </ContentCard>
                </Box> </a>


                <a href="https://uk.trustpilot.com/review/www.dermamina.com" target="_blank">        <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                    name="Soha"
                    image={trust}
                  >
              Mina is a very professional, honest and kind person. She made me...
                  </ContentCard>
                </Box> </a>
              </SliderStyled>
            </Col>
          </Row>

      

        </Container>
        </Section>
    </>
  );
};

export default RhinoRev;
