import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import trust from "../../assets/image/png/trust.svg";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import RoomBanner from './Abanner'
import imgMobile from "../../assets/image/png/mobile.png";
import icon3dModel from "../../assets/image/png/icon-3d-model.png";
import iconLayout from "../../assets/image/png/icon-layot-25.png";
import Videos from "../../sections/non/video.js";
import ShowMoreText from 'react-show-more-text';



const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;
  color: black;
  margin-bottom: 10px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;



const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`
  transform: rotate(10deg);
  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  max-width: 70%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    margin-left: 50px;
  }
`;



const Content16 = () => (


  <>
    {/* <!-- Content section 2 --> */}
    <Section bg="rgba(217, 211, 210, .5)" py="4" borderTop="2px solid #C2D9E1" borderBottom="2px solid #C2D9E1" className="pb-md-5 mb pt-md-5"  id="treatment">
      <Container className="pb-md-5 mb pt-md-5">

    
        <Row className="align-items-center mb-0 pb-0">
        
          <Col lg="12" md="9">
           
            <HeroT>WHAT IS A <Span color="primary"> NON SURGICAL RHINOPLASTY?</Span></HeroT>
            
              <Text>
              Many people wonder how the non-surgical nose job works as the thought of getting an ideal nose shape in just 10 minutes sounds unbelievable. With all the before and after profiles appearing on social media – some so shockingly transformative, one could easily mistaken them for classic rhinoplasty results. Most fascinating are the after results that show noses appearing smaller, especially as fillers enhance the nose. So, how is this possible?

              </Text>
              <br />
              <Text>
              <b>Non-surgical rhinoplasty in London, also known as the "10-minute nose job," is a safe, non-invasive alternative to traditional rhinoplasty. It is an ideal and inexpensive choice for those who want to improve the shape of their nose, rather than the size, with no downtime, no general anaesthesia, and no prolonged healing involved.</b>

              </Text>

            
<Text>
              <ShowMoreText
                /* Default options */
              
                more='Show more'
                less='Show less'
                className=''
                anchorClass=''
              
                expanded={false}
             
            >
         <br />
              <Text>
              The treatment is done by injecting hyaluronic acid (HA) based dermal fillers into the nose to temporarily reshape precise points of the nose, with small adjustments mainly to the bridge and tip of the nose. The hyaluronic acid filler settles into where it’s injected to hold the shape, instantly modifying the shape of the nose.

              </Text>

              <br />
              <Text>
              This procedure can also be reversed with hyalase injections if the outcome does not meet expectations. Unlike surgical rhinoplasty, non-surgical results are immediate and can last 9-18 months.

              </Text>
            </ShowMoreText>
            </Text>
         
          </Col>
         
        </Row>
      </Container>
    </Section>
  </>
);

export default Content16;
