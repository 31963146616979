import React from "react";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";
import Link from 'gatsby-link'
import { Title, Section, Box, Span } from "../../components/Core";
import imgC1 from "../../assets/image/jpeg/prp-blog.jpg";
import imgC2 from "../../assets/image/jpeg/fillers.jpg";
import imgC3 from "../../assets/image/jpeg/beard.jpg";
import imgC4 from "../../assets/image/jpeg/dfillers.jpg";
import imgC5 from "../../assets/image/jpeg/anti.jpg";
import imgC6 from "../../assets/image/jpeg/tear.jpg";
import imgC7 from "../../assets/image/jpeg/gsmile.jpg";
import imgC8 from "../../assets/image/jpeg/chinjaw.jpg";
import imgC9 from "../../assets/image/jpeg/eyelift.jpg";
import { device, breakpoints } from "../../utils";
import styled from 'styled-components';


const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;



const ContentCard = ({
  color = "primary",
  className,
  iconName,
  title,
  children,
  ...rest
}) => (
  <Box
    bg="#f1f1f1"
    border="1px solid"
    borderColor="border"
    p="20px"
    borderRadius={10}
    className={`d-flex align-items-center ${className}`}
    {...rest}
    css={`
      transition: all 0.3s ease-out;
      &:hover {
        box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow};`};
      }
    `}
  >
    <Box
      size={55}
      minWidth={55}
      borderRadius="50%"
      color={color}
    
      fontSize="28px"
      className="d-flex justify-content-center align-items-center"
      css={`
        background: url(${iconName})  ;
        background-size:cover;
      `}
      mr={3}
    >
      
    </Box>

    <Title variant="card" mb={0}>
      {title}
    </Title>
  </Box>
);

const Othertre = () => (
  <>
    <Section py={5} bg="#e9f0f2">
      <Container >
    
        <Row className="justify-content-center">


        <Col
            lg="4"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/prp-hair-restoration"><ContentCard
              title="Hair Loss Treatment"
              color="primary"
              iconName={imgC1}
            /></Link> 
          </Col>


          <Col
            lg="4"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/prp-beard-restoration-treatment"><ContentCard
              title="Beard Loss Treatment"
              color="primary"
              iconName={imgC3}
            /></Link> 
          </Col>
    
          <Col
            lg="4"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
            <Link to="/masseter"><ContentCard
              title="Masseter"
              color="ash"
              iconName={imgC2}
            /></Link>
          </Col>
          
        </Row>
        
        <Row className="justify-content-center">
  
       
  
          <Col
            lg="4"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/eyebrow-lift"><ContentCard
              title="Eyebrow Lift"
              color="primary"
              iconName={imgC9}
            /></Link> 
          </Col>
          <Col
            lg="4"
            md="6"
            className="mb-4"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-once="false"
            data-aos-delay="50"
          >
           <Link to="/gummy-smile"><ContentCard
              title="Gummy Smile"
              color="primary"
              iconName={imgC7}
            /></Link> 
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Othertre;
