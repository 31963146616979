import React from 'react'
import PageWrapper from "../components/PageWrapper";
import LandingBanner from '../sections/landing/MainB';
import LandingAbout from '../sections/landing/landingAbout.js';
import LandingReviews from '../sections/landing/landingReviews.js';
import Rte from '../sections/landing/popular';
import AC from '../sections/landing/Acontentone';
import ACthree from '../sections/landing/acthree';
import ACfour from '../sections/landing/acfour';
import ACfive from '../sections/landing/acfive';
import ACt from '../sections/landing/Acontenttwo';
import CTA from '../sections/non/noncta.js';
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Nongallery from "../sections/non/nongallery.js";
import Lan from "../sections/dermalfiller/otherher";
import Testimonial from "../sections/rhino/rhinoreviews";
import Anchoring from "../sections/rhino/anchor.js";
import Saveface from "../sections/landing/saveface";
import RhinoQu from "../sections/rhino/rhinoquestion";
import Recovery from "../sections/non/recovery.js";
import Abanner from '../sections/landing/Alanding';
import Blo from '../sections/landing/blo.js';
import Facts from '../sections/landing/lf.js';
import counter from '../sections/countersection/countersection.js'
import Sli from '../sections/rhino/rhinoslide';
import Pro from '../sections/landing/steps.js';
import Beauty from "../sections/non/nonbeauty.js";
import Acco from "../sections/non/nonfaq.js";
import Acthree from '../sections/landing/acthree';
import SectionOne from '../sections/countersection/countersection.js'
import Treatment from "../sections/homepage/othertreatments";

const DermalOtherPage = () => ( 
  <>
 <Head title="Other Treatments in London" image="/images/dermamina-home.jpg"  description="Balance or straighten your nose in a same-day appointment. A non-surgical rhinoplasty using dermal fillers is a safe, and non-invasive treatment. " keywords="Nose jobs, Rhinoplasty, Liquid nose job, Liquid rhinoplasty, Surgical nose job, Nose filler, Rhinoplasty filler, Non surgical rhinoplasty, No surgery nose job, Pain free nose job"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Tear Trough Fillers Under Eye',
item:'https://www.dermamina.com/tear-trough-under-eye',
},

]
}}
</JsonLd>
      <PageWrapper headerDark footerDark>
      <Lan />
      <Treatment />
     
   
    
 
   <Rte />
 
   <Saveface />
 
     </PageWrapper>
  
  </>
)
export default DermalOtherPage;
